import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { extractSteps, extractGroup } from './graphql';
import { Step } from 'src/types';
import { useLocation } from './hooks';

export const StepsContext = React.createContext<{ [key: string]: Step[] }>({});
export const useSteps = () => React.useContext(StepsContext);

interface Props {
  children: React.ReactNode;
}
export const StepsProvider = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query markdowns {
      allMdx {
        edges {
          node {
            frontmatter {
              title
              duration
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  const steps = extractSteps(data.allMdx);
  return <StepsContext.Provider value={steps}>{children}</StepsContext.Provider>;
};

export const useNextChapter = () => {
  const location = useLocation();
  const steps = useSteps();
  const group = extractGroup(location.pathname);
  const stack = steps[group] || [];
  const n = stack.findIndex(step => step.link === location.pathname);
  const nextChapter = stack[n + 1];
  if (nextChapter) return nextChapter;
  else if (group === 'basics' || group === 'advanced') {
    return {
      description: 'In this step we will choose the right tutorial together.',
      link: '/choose/',
      title: 'Choosing the right tutorial',
    };
  } else if (stack.length - 1 === n) {
    return {
      description: "We're proud that you finished this tutorial! Let's celebrate your success",
      link: '/success/',
      title: 'You did it!',
    };
  } else {
    return {
      description: '',
      link: '/',
      title: '',
    };
  }
};
