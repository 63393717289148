import React from 'react';
import App from 'src/components/App';
import { History } from '@reach/router';
import { graphql } from 'gatsby';
import { ProfilePageQuery } from 'types/graphql-types';
import { useCustomShortcode } from 'src/components/misc/MDXProvider';
import ContentOverview from 'src/components/home/ContentOverview';
import CustomHelmet from 'src/components/CustomHelmet';
import Result from 'src/components/Success/Result';
import Share from 'src/components/Success/Share';
import TogglePremium from 'src/components/Success/TogglePremium';
import Markdown from 'src/components/Tutorials/Markdown';
import ReactTooltip from 'react-tooltip';
import { useAuth0, customClaims } from 'src/utils/auth';

interface ProfilePageProps {
  post: ProfilePageQuery['mdx'];
}
const ProfilePage = ({ post }: ProfilePageProps) => {
  useCustomShortcode('ContentOverview', ContentOverview);
  const { user = {}, loading } = useAuth0();
  const isUserPremium = user[customClaims.premium];

  return (
    <div className="wrapper">
      <style jsx={true}>{`
        h1 {
          @p: .fw6, .mb60;
        }
        .wrapper {
          @p: .flex1, .flex, .flexColumn, .mhAuto, .h100, .overflowYScroll;
        }
        .container {
          @p: .w100, .mhAuto;
          max-width: 85rem;
        }
        .content {
          @p: .bbox, .pa25;
        }
        @media (max-width: 580px) {
          div.content {
            @p: .pa25, .bbox;
          }
        }
      `}</style>
      <CustomHelmet title={post?.frontmatter?.title} description={post?.frontmatter?.description || undefined} />
      <div className="container">
        <Result />
        {!loading && isUserPremium && <TogglePremium />}
        <Share />
        <div className="content">{post?.body && <Markdown>{post.body}</Markdown>}</div>
      </div>
    </div>
  );
};

interface Props {
  history: History;
  data: ProfilePageQuery;
}
export default ({ history, data: { mdx } }: Props) => {
  return (
    <App history={history}>
      <ProfilePage post={mdx} />
      <ReactTooltip delayShow={200} />
    </App>
  );
};

export const pageQuery = graphql`
  query ProfilePage($id: String!) {
    mdx(id: { eq: $id }) {
      body
      fields {
        slug
      }
      excerpt(pruneLength: 140)
      frontmatter {
        title
        description
        pageTitle
      }
    }
  }
`;
