import React, { useState, useMemo } from 'react';
import { useAuth0, customClaims } from 'src/utils/auth';
import api from 'src/api';
import cn from 'classnames';

function formatDate(date: Date) {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

export default function CancelPremium() {
  const [loading, setLoading] = useState(false);
  const { getAuth0User, user } = useAuth0();
  const expirationTimestamp = user[customClaims.subscriptionWillCancelAt];
  const isPremiumWillCancel = user[customClaims.premium] && expirationTimestamp;
  const renewalTimestamp = user[customClaims.subscriptionPeriodEndAt];
  const expirationFormattedDate = useMemo(() => {
    const expirationDate = new Date(expirationTimestamp * 1000);
    return formatDate(expirationDate);
  }, [expirationTimestamp]);
  const renewalFormattedDate = useMemo(() => {
    const renewalDate = new Date(renewalTimestamp * 1000);
    return formatDate(renewalDate);
  }, [renewalTimestamp]);
  function cancelSubscribe() {
    setLoading(true);
    const apiFunction = isPremiumWillCancel ? api.resumePremium : api.cancelPremium;
    apiFunction()
      .then(() => getAuth0User(true))
      .finally(() => {
        setLoading(false);
      });
  }
  const loadingText = isPremiumWillCancel ? 'Resuming...' : 'Canceling...';
  const actionText = isPremiumWillCancel ? 'Resume Subscription' : 'Cancel Subscription';
  return (
    <div className="share">
      <style jsx={true}>{`
        .block {
          @p: .pa60, .bt, .bBlack10;
          background: #fafafa;
        }
        h2 {
          @p: .f25, .black80, .mb25;
        }
        .expiration {
          @p: .f16, .red, .mt25, .mb16, .b;
        }
        .expiration.isRenewal {
          color: $primary;
        }
        .button {
          @p: .ph25, .pv12, .pointer, .inlineFlex, .itemsCenter, .bgRed;
          border-radius: 6px;
          border: none;
          color: #fff;
          outline: none;
        }
        .button.resume {
          background-color: $primary;
        }
        @media (max-width: 500px) {
          .block.block {
            @p: .pa25;
          }
        }
      `}</style>
      <div className="block">
        <h2>{isPremiumWillCancel ? 'Resume Premium Subscription' : 'Cancel Premium Subscription'}</h2>

        {
          <p className={cn('expiration', { isRenewal: !isPremiumWillCancel })}>
            {isPremiumWillCancel
              ? `The subscription will be canceled at ${expirationFormattedDate}`
              : `The subscription will be recharged at ${renewalFormattedDate}`}
          </p>
        }
        <button className={cn('button', { resume: isPremiumWillCancel })} onClick={cancelSubscribe}>
          {loading ? loadingText : actionText}
        </button>
      </div>
    </div>
  );
}
