import * as React from 'react';
import { getBackendTutorials, getFrontendTutorials } from 'src/utils/tutorials';
import Stack from 'src/components/Steps/Stack';
import { useSteps } from 'src/utils/StepsContext';

export default function ContentOverview() {
  const steps = useSteps();
  const frontendTutorials = getFrontendTutorials(steps);
  const backendTutorials = getBackendTutorials(steps);
  return (
    <section>
      <style jsx={true}>{`
        section {
          @p: .pt60;
        }
        h2 {
          @p: .pt0;
        }
        .content-overview {
          @p: .center;
        }
        p {
          @p: .tc;
        }
        .bordered {
          @p: .bb, .bt, .bBlack10, .bw2, .flex, .mt60;
        }
        .overview {
          @p: .flex;
        }
        .block {
          @p: .pa38;
        }
        .left {
          @p: .bBlack10, .br, .bw2, .flexFixed;
        }
        .right {
          @p: .flexAuto, .flex;
        }
        .advanced {
          @p: .mt38, .pt10;
        }
        .all-tutorials {
          @p: .ml38;
        }
        .all-tutorials :global(.stack) + :global(.stack) {
          @p: .mt38, .pt10;
          margin-left: 0 !important;
        }
        @media (max-width: 500px) {
          section {
            display: none;
          }
        }
      `}</style>
      <div className="content-overview">
        <h2>Content Overview</h2>
        <p>Here is an overview of all the tutorials and their contents that you find on this site. </p>
      </div>
      <div className="bordered">
        <div className="content-overview">
          <div className="overview">
            <div className="block left">
              <Stack name="GraphQL Fundamentals" steps={steps.basics} />
              <div className="advanced">
                <Stack name="Advanced GraphQL" steps={steps.advanced} />
              </div>
            </div>
            <div className="block right">
              <Stack name="React + Apollo" steps={steps['react-apollo']} />
              <Stack name="React + Relay" steps={steps['react-relay']} />
              <Stack name="NodeJS" steps={steps['graphql-js']} />
              <div className="all-tutorials">
                <Stack name="All Frontend Tutorials" steps={frontendTutorials} showLines={false} />
                <Stack name="All Backend Tutorials" steps={backendTutorials} showLines={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
