import React, { useMemo } from 'react';
import cn from 'classnames';
import Icon from 'src/components/Icon';
import Link from 'gatsby-link';
import { useCompletedCourses } from 'src/utils/completeCourse';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {}

function Result({}: Props) {
  const [completedCourses] = useCompletedCourses();
  const numOfLessonsCompleted = Object.values(completedCourses)
    .map(c => c.completed)
    .reduce((a, b) => (b ? a + 1 : a), 0);

  const {
    allCourse: { nodes: courses },
    getDefaultCourse: { id: defaultCourseId },
  } = useStaticQuery(graphql`
    query GetAllLessons {
      getDefaultCourse {
        id
      }
      allCourse {
        nodes {
          id
          name
          comingSoon
          lessons {
            id
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const filteredCourses = useMemo(
    () =>
      courses
        .filter(({ comingSoon }: { comingSoon: boolean }) => !comingSoon)
        .filter(({ id }: { id: string }) => id !== defaultCourseId),
    [courses],
  );

  const completedLessonsSlugs = Object.entries(completedCourses)
    .filter(([, c]) => c.completed)
    .map(([slug]) => slug);

  const startedCourses = filteredCourses
    .map((course: any) => ({
      ...course,
      completedLessonsCount: course.lessons.reduce((a: number, b: any) => {
        return completedLessonsSlugs.includes(b.fields.slug) ? a + 1 : a;
      }, 0),
    }))
    .map((course: any) => ({
      ...course,
      completedPercent: (course.completedLessonsCount / course.lessons.length) * 100,
    }))
    .slice()
    .sort((a: any, b: any) => (a.completedLessonsCount < b.completedLessonsCount ? 1 : -1));

  const allLessonsCount = filteredCourses.reduce((a: number, b: any) => a + b.lessons.length, 0);
  const remainingStartedLessonsCount = allLessonsCount - numOfLessonsCompleted;

  const completePercentage = Math.round((numOfLessonsCompleted / allLessonsCount) * 100);

  return (
    <div className={`${completePercentage === 100 ? 'perfect' : ''} result`}>
      <style jsx={true}>{`
        .result {
          @p: .pa60, .relative;
          background: #f9f9f9;
        }
        .medal {
          @p: .absolute;
          top: -70px;
          right: 60px;
        }
        .perfect.result .medal :global(svg) {
          fill: #e00082;
        }
        h1 {
          @p: .fw6, .primary;
          font-size: 30px;
        }
        p {
          @p: .f16, .lhCopy, .black50, .mt16;
          max-width: 514px;
        }
        .bars {
          @p: .mt60, .flex, .flexColumn, .flexWrap;
        }
        @media (max-width: 580px) {
          div.result {
            @p: .pa25;
          }
          div.bars {
            @p: .flexWrap;
          }
        }
      `}</style>
      <div className="medal">
        <Icon
          src={require('../../assets/icons/success-badge.svg')}
          color={'rgba(0,0,0,.05)'}
          width={212}
          height={339}
        />
      </div>
      <h1>You have completed {completePercentage}% of all the courses.</h1>
      <p>
        You have completed {numOfLessonsCompleted} of {allLessonsCount} lessons. Keep up the good job!{' '}
        {remainingStartedLessonsCount
          ? `Only ${remainingStartedLessonsCount} lessons left to reach the mastery level!`
          : ''}
      </p>
      <div className="bars">
        {startedCourses.sort().map((course: any) => (
          <Bar
            key={course.id}
            items={course.lessons.map((lesson: any) => ({
              path: lesson.fields.slug,
              completed: completedLessonsSlugs.includes(lesson.fields.slug),
              title: lesson.frontmatter.title,
            }))}
            percent={course.completedPercent}
            title={course.name}
          />
        ))}
      </div>
    </div>
  );
}

export default Result;

interface ScoreStep {
  path: string;
  completed: boolean;
  title: string;
}

interface BarProps {
  items: ScoreStep[];
  title: string;
  percent: number;
}

function Bar({ items, title, percent }: BarProps) {
  const doneCount = items.reduce((total, curr) => (curr.completed ? total + 1 : total), 0);
  const totalCount = items.length;
  return (
    <div className="bar">
      <style jsx={true}>{`
        .bar {
          @p: .mb12;
        }
        .cells {
          @p: .flex, .flexWrap, .mv16;
        }
        .cell {
          @p: .mr4, .ba;
          width: 12px;
          height: 12px;
        }
        .label {
          @p: .f12, .fw6, .primary70, .ttu;
        }
        .incomplete {
          border-color: rgba(0, 0, 0, 0.15);
        }
        .complete {
          @p: .bgPrimary;
          border-color: transparent;
        }
        @media (max-width: 580px) {
          div.bar + div.bar {
            @p: .mt25;
          }
        }
      `}</style>
      <div className="label">
        {title} ({doneCount}/{totalCount})
      </div>
      <div className="cells">
        {items.map((item, i) => (
          <Link key={item.path} to={item.path}>
            <div
              className={cn('cell', { complete: item.completed, incomplete: !item.completed })}
              key={i}
              data-tip={item.title}
            />
          </Link>
        ))}
        <span className="label">{Math.round(percent)}%</span>
      </div>
    </div>
  );
}
